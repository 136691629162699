<script setup lang="ts"></script>

<template>
    <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_271_3463)">
            <path
                d="M35.6207 24.6651L32.2097 21.2539L25.1535 28.3101C23.8939 29.5697 22.313 30.3909 20.5958 30.6983L25.0917 35.1941C25.5034 35.6061 26.1714 35.6061 26.5831 35.1941L35.6207 26.1568C36.0327 25.7448 36.0327 25.0768 35.6207 24.6651Z"
                fill="url(#paint0_linear_271_3463)"
            />
            <path
                d="M30.8202 19.6603C32.0092 18.4716 32.0095 16.5371 30.8219 15.3476C30.8205 15.3462 30.8191 15.3448 30.8178 15.3435C30.5239 15.0499 30.1806 14.8235 29.8068 14.6722C30.1308 13.8524 30.1992 12.4659 29.1256 11.392C29.1229 11.3892 29.1201 11.3862 29.1171 11.3834C28.8246 11.0929 28.4834 10.8687 28.1121 10.7182C28.4362 9.89892 28.5049 8.51217 27.4318 7.4388C27.431 7.43798 27.4304 7.43743 27.4299 7.43688C27.1357 7.14272 26.7921 6.91585 26.4177 6.76452C26.7418 5.94466 26.8102 4.55791 25.7366 3.484C25.7363 3.484 25.7369 3.484 25.7366 3.484C25.7363 3.48372 25.7355 3.48317 25.7352 3.4829C24.5457 2.29335 22.6104 2.29335 21.4209 3.4829L19.4211 5.48241L20.4436 6.50497C22.1476 8.20895 22.1476 10.9813 20.4439 12.6853C19.8704 13.2585 19.1659 13.6524 18.398 13.84C18.2142 14.5887 17.8308 15.2984 17.2466 15.8824C16.674 16.455 15.9706 16.8486 15.2034 17.0365C15.0158 17.8033 14.622 18.507 14.0496 19.0794C13.4769 19.652 12.7735 20.0456 12.0064 20.2335C11.8188 21.0003 11.425 21.704 10.8526 22.2767C10.0272 23.1018 8.92969 23.5566 7.7624 23.5566C6.5951 23.5566 5.49756 23.102 4.67221 22.2764L3.64966 21.2538L0.238678 24.6651C-0.173309 25.0771 -0.173309 25.7448 0.238678 26.1568L9.27604 35.1941C9.68803 35.6061 10.3557 35.6059 10.7677 35.1941L17.237 28.7251H19.0596C20.7982 28.7251 22.4327 28.0478 23.6621 26.8187L30.8202 19.6603Z"
                fill="url(#paint1_linear_271_3463)"
            />
            <path
                d="M6.16388 20.785C7.04664 21.668 8.47816 21.668 9.36091 20.785C10.2439 19.9023 10.2439 18.471 9.36091 17.588C10.2439 18.471 11.6752 18.471 12.5582 17.588C13.441 16.7052 13.441 15.2737 12.5582 14.391C13.441 15.2737 14.8725 15.2737 15.7552 14.391C16.638 13.5082 16.638 12.0767 15.7552 11.1939C16.638 12.0767 18.0695 12.0767 18.9523 11.1939C19.8353 10.3109 19.8353 8.87966 18.9523 7.99664L14.4446 3.48894C13.5618 2.60619 12.1303 2.60619 11.2475 3.48894C10.3648 4.37169 10.3648 5.80321 11.2475 6.68597L11.8122 7.25094C10.9295 6.36791 9.49796 6.36791 8.61521 7.25094C7.73246 8.13369 7.73246 9.56521 8.61521 10.448L9.74488 11.5776C8.86213 10.6949 7.43061 10.6949 6.54786 11.5776C5.66483 12.4604 5.66483 13.8919 6.54786 14.7747L7.67753 15.9046C6.7945 15.0216 5.36325 15.0216 4.48023 15.9046C3.59748 16.7874 3.59748 18.2186 4.48023 19.1016L6.16388 20.785Z"
                fill="url(#paint2_linear_271_3463)"
            />
            <path
                d="M19.9292 1.99123C20.0852 1.83522 20.2503 1.69185 20.422 1.55864L19.8051 0.941759C19.4843 0.620684 18.9935 0.541307 18.5878 0.744005L15.9846 2.04561L17.9297 3.99102L19.9292 1.99123Z"
                fill="url(#paint3_linear_271_3463)"
            />
        </g>
        <defs>
            <linearGradient
                id="paint0_linear_271_3463"
                x1="17.9297"
                y1="0.632812"
                x2="17.9297"
                y2="35.5031"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#8CD293" />
                <stop offset="1" stop-color="#63B082" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_271_3463"
                x1="17.9297"
                y1="0.632812"
                x2="17.9297"
                y2="35.5031"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#8CD293" />
                <stop offset="1" stop-color="#63B082" />
            </linearGradient>
            <linearGradient
                id="paint2_linear_271_3463"
                x1="17.9297"
                y1="0.632812"
                x2="17.9297"
                y2="35.5031"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#8CD293" />
                <stop offset="1" stop-color="#63B082" />
            </linearGradient>
            <linearGradient
                id="paint3_linear_271_3463"
                x1="17.9297"
                y1="0.632812"
                x2="17.9297"
                y2="35.5031"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#8CD293" />
                <stop offset="1" stop-color="#63B082" />
            </linearGradient>
            <clipPath id="clip0_271_3463">
                <rect width="36" height="36" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
